import { FormEvent, MouseEvent, useEffect, useState } from "react"
import { Picture } from "./picture"
//@ts-ignore
import Thumb from "../assets/thumb-down.gif"

interface DataTypes {
  status: string
  files: Array<string>
  detail: {
    firstname: string
    lastname: string
    party: string
    canton: string
    council: string
  }
}

enum ResultType {
  "Correct" = "Correct",
  "Incorrect" = "Incorrect",
}
export type ShowResultType = ResultType | false

const randomNumber = () => Math.round(Math.random())

export const Game = () => {
  const [isLoading, setIsLoading] = useState<Boolean>(true)
  const [data, setData] = useState<DataTypes | null>(null)
  const [isReversedOrder] = useState(randomNumber)
  const [showResult, setShowResult] = useState<ShowResultType>(false)

  const _onSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (!showResult) {
      const nativeEvent: any = e.nativeEvent
      const submitter: HTMLButtonElement = nativeEvent.submitter
      const i = parseInt(submitter.getAttribute("data-index") || "")
      const isReal = Boolean(i - isReversedOrder)
      setShowResult(isReal ? ResultType.Correct : ResultType.Incorrect)
    }
  }

  const _onContinue = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()

    fetchData()
  }

  const fetchData = () => {
    setIsLoading(true)
    setShowResult(false)
    fetch(
      process.env.NODE_ENV !== "production"
        ? "http://localhost:8000/api/getGame"
        : "/api/getGame",
    )
      .then((response) => response.json())
      .then((data: DataTypes) => {
        if (data.status === "SUCCESS") setData(data)
        setIsLoading(false)
      })
      .catch((err) => {
        setData(null)
        console.error(err)
      })
  }

  useEffect(() => {
    fetchData()
    return () => {
      setData(null)
    }
  }, [])

  if (!data)
    return (
      <div className="filler">
        <div className="filler__child">
          {isLoading ? (
            <div className="spinner-border" role="status"></div>
          ) : (
            <div className="spinner-grow" role="status"></div>
          )}
        </div>
        <div className="filler__child">
          {isLoading ? (
            <div className="spinner-border" role="status"></div>
          ) : (
            <div className="spinner-grow" role="status"></div>
          )}
        </div>
      </div>
    )

  return data ? (
    <>
      <form onSubmit={_onSubmit} className="game">
        {!isReversedOrder
          ? data.files.map((img, index) => (
              <Picture
                url={img}
                key={img}
                fetchData={fetchData}
                index={index}
                showResult={showResult}
              />
            ))
          : [...data.files]
              .reverse()
              .map((img, index) => (
                <Picture
                  url={img}
                  key={img}
                  fetchData={fetchData}
                  index={index}
                  showResult={showResult}
                />
              ))}
      </form>
      <h2>
        {data.detail.firstname} {data.detail.lastname}
      </h2>
      <span>{data.detail.council}</span> | <span>{data.detail.party}</span> |{" "}
      <span>{data.detail.canton}</span>
      {showResult ? (
        <div className="overlay">
          <>
            {showResult === ResultType.Correct ? (
              <>
                <img alt="" src={Thumb} className="icon icon__up" />
                <h2>Richtig</h2>
              </>
            ) : null}

            {showResult === ResultType.Incorrect ? (
              <>
                <img alt="" src={Thumb} className="icon icon__down" />
                <h2>Falsch</h2>
              </>
            ) : null}
          </>
          <button className="button" onClick={_onContinue}>
            Weiter
          </button>
        </div>
      ) : null}
    </>
  ) : null
}
