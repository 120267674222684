import { ShowResultType } from "./game"
type PictureProps = {
  url: string
  fetchData: VoidFunction
  index: number
  showResult: ShowResultType
}

export const Picture = ({
  url,
  fetchData,
  index,
  showResult,
}: PictureProps) => {
  return (
    <button
      className="game__button"
      type="submit"
      data-index={index}
      disabled={!!showResult}
    >
      <img
        className="game__image"
        alt=""
        src={url}
        onError={(e) => {
          console.error(e)
          fetchData()
        }}
      />
    </button>
  )
}
